<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">堃晟入选福建省污染地块调查评估、治理修复单位专业机构推荐名录</div>
      <p class="time">2021.03.25</p>

      <p class="space_content" style="text-indent:0">
        近日，《福建省污染地块调查评估、治理修复单位专业机构推荐名录（2021年度）》已经发布。
      </p>
      <p class="space_content" style="text-indent:0">
        历思物证科技产研体系成员企业——
        <span style="font-weight: 600">
          厦门堃晟检测技术有限公司
        </span>
        （以下简称“
        <span style="font-weight: 600">
          堃晟
        </span>
        ”）成功入选
        <span style="font-weight: 600">
          调查评估类、方案编制类、效果评估类
        </span>
        等类目推荐机构。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/38-1.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/38-2.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/38-3.png')">
      </div>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/37'"><p class="ellipsis1">厦门市税务局稽查局办公室和检查三科党支部与历思共建学习交流会！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/39'"><p class="ellipsis1">喜讯！热烈祝贺历思科技通过高新技术企业认定！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
